import { API } from "aws-amplify";

const getFeedData = async () => {
    const path = "encoder_dashboard_api/read";
    console.log(
        `Executing  API.get for getFeedData on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`
    );

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getFeedData function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: {
            "Cache-Control": "no-store",
            "Content-type": "application/json",
        },
        queryStringParameters: {
            action: "get_feed_data",
        },
    }).catch(handleError);
    const data = await response;
    return data;
};

export { getFeedData };
