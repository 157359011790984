import React, { useContext } from "react";
import { Button, Card, CardContent, CardMedia, Link, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

//Local Imports
import { GlobalContext } from "../contexts/GlobalContextStore";
import { getSCTElog, getEventlog, getEventXML } from "../js/encApiUtils";
import { useStreamApiQuery, useThumbnailApiQuery, useGetConfigApiQuery } from "../js/useQueryUtils";

import {
    getStreamLink,
    getStreamEventNameAndId,
    getStreamUpTime,
    getStreamFrameRate,
    getStreamActiveInput,
    getStreamAudioLevel,
    getStreamActiveAlerts,
    getStreamHwStatus,
    getStreamAudioNorm,
    getStreamThumbnailData,
} from "../js/dashboardUtils";

export const StreamViewCard = (props) => {
    const [storeStates, storeDispatch] = useContext(GlobalContext);
    const streamApiQueryResult = useStreamApiQuery(props.streamName);
    const thumbnailApiQueryResult = useThumbnailApiQuery(streamApiQueryResult.data);
    const getConfigApiQueryResult = useGetConfigApiQuery(streamApiQueryResult.data);

    /**
     * Handles Show SCTE data button click. Makes API call and  Sets local/global states
     * @param {*} data - Stream related data
     * @returns {null}
     */
    const handleShowScteData = async (data) => {
        storeDispatch({ type: "SHOW_SCTE_EVENTS", showScteEvents: true });

        if (data !== undefined && data[0] !== undefined) {
            const queryParams = {
                event_id: data[0].event_id,
                lookback_minutes: "5",
                live_service_endpoint: data[0].live_service_endpoint,
            };

            // startTime = Math.round((new Date()).getTime() / 1000);
            const scteLogData = await getSCTElog(queryParams);
            // endTime = Math.round((new Date()).getTime() / 1000);

            const scteObj = {
                stream_name: data.stream_name,
                scte_events: scteLogData[0]["scte_data"],
            };
            storeDispatch({ type: "SET_SCTE_DATA", scteData: scteObj });
        }
    };

    /**
     * Handles logs download button. Makes API call and  Set local , global states
     * @param {*} data
     * @return {null}
     */
    const handleLogsDownload = async (data) => {
        const queryParams = {
            event_id: data.event_id,
            event_name: data.event_name,
            live_service_endpoint: data.live_service_endpoint,
        };
        const eventLogData = await getEventlog(queryParams);

        const x = document.createElement("A");
        x.setAttribute("href", eventLogData.logfile_url);
        x.setAttribute("target", "_blank");
        x.setAttribute("download", "logFile.json");
        document.body.appendChild(x);
        x.click();
        URL.revokeObjectURL(eventLogData.logfile_url);
        document.body.removeChild(x);
    };

    /**
     * Handles XML data download on button-Click. Makes API call and Sets local, global states.
     * @param {*} data
     */
    const handleXmlDownload = async (data) => {
        const queryParams = {
            event_id: data.event_id,
            live_service_endpoint: data.live_service_endpoint,
        };
        const eventXmlData = await getEventXML(queryParams);
        //Develop further
        console.log(eventXmlData);
    };

    return (
        <Card>
            <CardContent>
                {streamApiQueryResult.status === "loading" ? (
                    <Box sx={{ display: "flex" }}>
                        {" "}
                        <CircularProgress />{" "}
                    </Box>
                ) : streamApiQueryResult.status === "error" ? (
                    <span>Error: {streamApiQueryResult.error.message}</span>
                ) : (
                    <>
                        {/* <CardContent> */}
                        <Typography variant="body1" display="block">
                            {props.streamName}
                        </Typography>

                        {storeStates.attributeState.encoder_link && (
                            <Typography variant="caption" display="block">
                                {"Encoder: "}
                                <Link
                                    sx={{ fontSize: "0.8rem" }}
                                    href={
                                        getStreamLink(
                                            streamApiQueryResult.data,
                                            storeStates.dashboardFeedQueryData.dbQueryData[0]
                                        ).thisHref
                                    }
                                    target="_blank"
                                >
                                    {
                                        getStreamLink(
                                            streamApiQueryResult.data,
                                            storeStates.dashboardFeedQueryData.dbQueryData[0]
                                        ).thisLink
                                    }
                                </Link>
                            </Typography>
                        )}

                        {storeStates.attributeState.encoder_event && (
                            <Typography variant="caption" display="block">
                                Event: {getStreamEventNameAndId(streamApiQueryResult.data).thisStreamEventName} [
                                {getStreamEventNameAndId(streamApiQueryResult.data).thisStreamEventId}]
                            </Typography>
                        )}

                        {storeStates.attributeState.event_stats && (
                            <div>
                                <Typography variant="caption" display="block">
                                    UpTime: {getStreamUpTime(getConfigApiQueryResult.data).thisStreamUpTime}
                                </Typography>

                                <Typography variant="caption" display="block">
                                    Video Frame Rate:{" "}
                                    {getStreamFrameRate(getConfigApiQueryResult.data).thisStreamFrameRate}
                                </Typography>

                                <Typography variant="caption" display="block">
                                    Active input on {getStreamActiveInput(getConfigApiQueryResult.data).thisStreamInput}{" "}
                                    : {getStreamActiveInput(getConfigApiQueryResult.data).thisStreamUri}
                                </Typography>

                                <Typography mb={2} variant="caption" display="block">
                                    Audio Level:{" "}
                                    {getStreamAudioLevel(getConfigApiQueryResult.data).thisStreamAudioLevel}
                                </Typography>
                            </div>
                        )}

                        {storeStates.attributeState.image_thumbnail && (
                            <CardMedia
                                component="img"
                                sx={{
                                    aspectRatio: 16 / 9,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    // objectFit: "contain",
                                    padding: 1,
                                    // backgroundColor: "grey",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                }}
                                image={
                                    "data:image/jpeg;base64," +
                                    window.btoa(
                                        getStreamThumbnailData(thumbnailApiQueryResult.data).thisStreamThumbnailData
                                    )
                                }
                                alt="Event Thumbnail Image"
                            />
                        )}

                        {storeStates.attributeState.active_alerts && (
                            <Typography mt={2} variant="caption" display="block">
                                Active Alerts:{" "}
                                {getStreamActiveAlerts(getConfigApiQueryResult.data).thisStreamActiveAlerts}
                            </Typography>
                        )}

                        {storeStates.attributeState.encoder_stats && (
                            <div>
                                <Typography variant="caption" display="block">
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "fit-content",
                                            borderRadius: 1,
                                            "& hr": {
                                                mx: 1.0,
                                            },
                                        }}
                                    >
                                        Disk: {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamDisk}
                                        %
                                        <Divider orientation="vertical" flexItem />
                                        RAM: {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamMem}
                                        %
                                        <Divider orientation="vertical" flexItem />
                                        CPU: {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamCpu}%
                                    </Box>
                                </Typography>

                                <Typography variant="caption" display="block">
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "fit-content",
                                            borderRadius: 1,
                                            "& hr": {
                                                mx: 1.0,
                                            },
                                        }}
                                    >
                                        GPU: {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu1}
                                        %
                                        <Divider orientation="vertical" flexItem />
                                        {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu2}
                                        %
                                        <Divider orientation="vertical" flexItem />
                                        {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu3}
                                        %
                                        <Divider orientation="vertical" flexItem />
                                        {getStreamHwStatus(getConfigApiQueryResult.data).thisStreamGpu4}%
                                    </Box>
                                </Typography>

                                <Typography variant="caption" display="block">
                                    Audio normalization:{" "}
                                    {getStreamAudioNorm(getConfigApiQueryResult.data).thisStreamAudioNorm}
                                </Typography>
                            </div>
                        )}

                        {storeStates.attributeState.download_buttons && (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "fit-content",
                                    borderRadius: 1,
                                    mt: 1,
                                    "& hr": {
                                        mx: 1.0,
                                    },
                                }}
                            >
                                <Button
                                    variant="text"
                                    color="primary"
                                    sx={{ fontSize: 10, padding: "4px 2px" }}
                                    onClick={() => handleShowScteData(streamApiQueryResult.data)}
                                >
                                    SCTE35 Information
                                </Button>
                                <Divider orientation="vertical" flexItem />
                                <Button
                                    disabled
                                    variant="text"
                                    color="secondary"
                                    sx={{ fontSize: 10, padding: "4px 2px" }}
                                    onClick={() => handleLogsDownload(streamApiQueryResult.data)}
                                >
                                    Logs Download
                                </Button>
                                <Divider orientation="vertical" flexItem />
                                <Button
                                    disabled
                                    variant="text"
                                    color="success"
                                    sx={{ fontSize: 10, padding: "4px 2px" }}
                                    onClick={() => handleXmlDownload(streamApiQueryResult.data)}
                                >
                                    XML Download
                                </Button>
                            </Box>
                        )}
                        {/* </CardContent> */}
                        {storeStates.attributeState.progress_bar && (
                            <div>
                                {streamApiQueryResult.isFetching ? (
                                    <Box sx={{ width: "80%" }}>
                                        {" "}
                                        <LinearProgress />{" "}
                                    </Box>
                                ) : (
                                    " "
                                )}
                            </div>
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    );
};
