import React, { useContext } from "react";
import Grid from "@mui/material/Grid";

//Local Imports
import { GlobalContext } from "../contexts/GlobalContextStore";
import { CustomLabelCard } from "./CustomLabelCard";
import { StreamViewCard } from "./StreamViewCard";
import { ZixiStreamViewCard } from "./ZixiStreamViewCard";
import { isFeed, getStreamsFromAlias, getArchTypeFromAlias, getArchTypeFromStream } from "../js/dashboardUtils";

const ShowStreamViewCard = ({ streamName }) => {
    const [storeStates] = useContext(GlobalContext);
    if (!isFeed(streamName)) {
        let thisArchType = getArchTypeFromStream(streamName, storeStates.dashboardFeedQueryData.dbQueryData[0]);
        if (thisArchType === "zixi") {
            return <ZixiStreamViewCard key={streamName} streamName={streamName} />;
        } else {
            return <StreamViewCard key={streamName} streamName={streamName} />;
        }
    } else {
        return;
    }
};

const ShowViewWithCustomLabelField = ({ feedName }) => {
    const [storeStates] = useContext(GlobalContext);

    if (isFeed(feedName)) {
        let thisStreamsArray = getStreamsFromAlias(feedName, storeStates.dashboardFeedQueryData.dbQueryData[0]);
        let thisArchType = getArchTypeFromAlias(feedName, storeStates.dashboardFeedQueryData.dbQueryData[0]);
        let showCustomLabelFieldComponentData = [];
        if (thisArchType === "zixi") {
            showCustomLabelFieldComponentData = thisStreamsArray.map((stream) => (
                <Grid key={stream} item xs={12 / 5} md={12 / 5} lg={12 / 5} xl={12 / 5}>
                    <ZixiStreamViewCard key={stream} streamName={stream} />
                </Grid>
            ));
        } else {
            showCustomLabelFieldComponentData = thisStreamsArray.map((stream) => (
                <Grid key={stream} item xs={12 / 5} md={12 / 5} lg={12 / 5} xl={12 / 5}>
                    <StreamViewCard key={stream} streamName={stream} />
                </Grid>
            ));
        }
        showCustomLabelFieldComponentData.push(
            <Grid key={`${feedName}-${Math.random()}`} item xs={12 / 5} md={12 / 5} lg={12 / 5} xl={12 / 5} p={2}>
                <CustomLabelCard key={`${feedName}-${Math.random()}`} feedName={feedName} />
            </Grid>
        );
        return showCustomLabelFieldComponentData;
    } else {
        return [feedName];
    }
};

/**
 * Encoder data display component
 * @param {*} props
 * @returns {JSX} JSX representation of Encoder data display component
 */
export default function StreamViewer(props) {
    const [storeStates] = useContext(GlobalContext);

    return (
        <React.Fragment>
            {/* {console.log(storeStates.feedNameList)}
      {console.log("adding to avocViewFeedList")}
      {console.log(storeStates.avocViewFeedList)} */}

            {!storeStates.attributeState.showCustomLabelField
                ? storeStates.feedNameList.map((stream) => (
                      <Grid key={stream} item xs={12} md={6} lg={3}>
                          {/* <StreamViewCard key={stream} streamName={stream} /> */}
                          <ShowStreamViewCard key={stream} streamName={stream} />
                      </Grid>
                  ))
                : storeStates.avocViewFeedList.map((feed, idx) => (
                      <ShowViewWithCustomLabelField key={idx} feedName={feed} />
                  ))}
        </React.Fragment>
    );
}
