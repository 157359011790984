import React, { useContext } from "react";
import { Card, CardContent, CardMedia, Link, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { Box } from "@mui/system";
import LinearProgress from "@mui/material/LinearProgress";
import CircularProgress from "@mui/material/CircularProgress";

//Local Imports
import { GlobalContext } from "../contexts/GlobalContextStore";
import { useZixiStreamApiQuery, useZixiThumbnailApiQuery, useGetZixiConfigApiQuery } from "../js/useZixiQueryUtils";

import {
    getZixiStreamLink,
    getZixiStreamEventNameAndId,
    getZixiStreamUpTime,
    getZixiStreamInputBitrate,
    getZixiStreamActiveInput,
    getZixiStreamAudioLevel,
    getZixiStreamActiveAlerts,
    getZixiStreamHwStatus,
    getZixiStreamAudioNorm,
    getZixiStreamThumbnailData,
} from "../js/zixiDashboardUtils";

export const ZixiStreamViewCard = (props) => {
    const [storeStates] = useContext(GlobalContext);
    const zixiStreamApiQueryResult = useZixiStreamApiQuery(props.streamName);
    const zixiThumbnailApiQueryResult = useZixiThumbnailApiQuery(zixiStreamApiQueryResult.data);
    const getZixiConfigApiQueryResult = useGetZixiConfigApiQuery(zixiStreamApiQueryResult.data);

    return (
        <Card>
            <CardContent>
                {zixiStreamApiQueryResult.status === "loading" ? (
                    <Box sx={{ display: "flex" }}>
                        {" "}
                        <CircularProgress />{" "}
                    </Box>
                ) : zixiStreamApiQueryResult.status === "error" ? (
                    <span>Error: {zixiStreamApiQueryResult.error.message}</span>
                ) : (
                    <>
                        {/* <CardContent> */}
                        <Typography variant="body1" display="block">
                            {props.streamName}
                        </Typography>

                        {storeStates.attributeState.encoder_link && (
                            <Typography variant="caption" display="block">
                                {"Zixi: "}
                                <Link
                                    sx={{ fontSize: "0.8rem" }}
                                    href={getZixiStreamLink(zixiStreamApiQueryResult.data).thisHref}
                                    target="_blank"
                                >
                                    {getZixiStreamLink(zixiStreamApiQueryResult.data).thisLink}
                                </Link>
                            </Typography>
                        )}

                        {storeStates.attributeState.encoder_event && (
                            <Typography variant="caption" display="block">
                                Event: {getZixiStreamEventNameAndId(zixiStreamApiQueryResult.data).thisStreamEventName}{" "}
                                [{getZixiStreamEventNameAndId(zixiStreamApiQueryResult.data).thisStreamEventId}]
                            </Typography>
                        )}

                        {storeStates.attributeState.event_stats && (
                            <div>
                                <Typography variant="caption" display="block">
                                    UpTime: {getZixiStreamUpTime(getZixiConfigApiQueryResult.data).thisStreamUpTime}
                                </Typography>

                                <Typography variant="caption" display="block">
                                    Input Bitrate:{" "}
                                    {getZixiStreamInputBitrate(getZixiConfigApiQueryResult.data).thisInputBitrate}
                                </Typography>

                                <Typography variant="caption" display="block">
                                    Active input state:{" "}
                                    {getZixiStreamActiveInput(getZixiConfigApiQueryResult.data).thisStreamUri}
                                </Typography>

                                <Typography mb={2} variant="caption" display="block">
                                    Audio Level:{" "}
                                    {getZixiStreamAudioLevel(getZixiConfigApiQueryResult.data).thisStreamAudioLevel}
                                </Typography>
                            </div>
                        )}

                        {storeStates.attributeState.image_thumbnail && (
                            <CardMedia
                                component="img"
                                sx={{
                                    aspectRatio: 16 / 9,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    // objectFit: "contain",
                                    padding: 1,
                                    // backgroundColor: "grey",
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                }}
                                image={
                                    "data:image/jpeg;base64," +
                                    window.btoa(
                                        getZixiStreamThumbnailData(zixiThumbnailApiQueryResult.data)
                                            .thisStreamThumbnailData
                                    )
                                }
                                alt="Event Thumbnail Image"
                            />
                        )}

                        {storeStates.attributeState.active_alerts && (
                            <Typography mt={2} variant="caption" display="block">
                                Active Alerts:{" "}
                                {getZixiStreamActiveAlerts(getZixiConfigApiQueryResult.data).thisStreamActiveAlerts}
                            </Typography>
                        )}

                        {storeStates.attributeState.encoder_stats && (
                            <div>
                                <Typography variant="caption" display="block">
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "fit-content",
                                            borderRadius: 1,
                                            "& hr": {
                                                mx: 1.0,
                                            },
                                        }}
                                    >
                                        Disk: {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamDisk}
                                        %
                                        <Divider orientation="vertical" flexItem />
                                        RAM: {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamMem}
                                        %
                                        <Divider orientation="vertical" flexItem />
                                        CPU: {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamCpu}%
                                    </Box>
                                </Typography>

                                <Typography variant="caption" display="block">
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "fit-content",
                                            borderRadius: 1,
                                            "& hr": {
                                                mx: 1.0,
                                            },
                                        }}
                                    >
                                        GPU: {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu1}
                                        %
                                        <Divider orientation="vertical" flexItem />
                                        {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu2}
                                        %
                                        <Divider orientation="vertical" flexItem />
                                        {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu3}
                                        %
                                        <Divider orientation="vertical" flexItem />
                                        {getZixiStreamHwStatus(getZixiConfigApiQueryResult.data).thisStreamGpu4}%
                                    </Box>
                                </Typography>

                                <Typography variant="caption" display="block">
                                    Audio normalization:{" "}
                                    {getZixiStreamAudioNorm(getZixiConfigApiQueryResult.data).thisStreamAudioNorm}
                                </Typography>
                            </div>
                        )}

                        {/* </CardContent> */}
                        {storeStates.attributeState.progress_bar && (
                            <div>
                                {zixiStreamApiQueryResult.isFetching ? (
                                    <Box sx={{ width: "80%" }}>
                                        {" "}
                                        <LinearProgress />{" "}
                                    </Box>
                                ) : (
                                    " "
                                )}
                            </div>
                        )}
                    </>
                )}
            </CardContent>
        </Card>
    );
};
