import { createTheme } from "@mui/material";

const DashboardTheme = (toggleDark) =>
  createTheme({
    // Theme settings
    palette: {
      mode: toggleDark ? "dark" : "light",
      primary: {
        light: "#757ce8",
        // main: '#3f50b5',
        main: "#FF9900", //Amazon primary color
        dark: "#002884",
        contrastText: "#fff",
      },
      secondary: {
        light: "#ff7961",
        main: "#f44336",
        dark: "#ba000d",
        contrastText: "#000",
      },
    },
  });

export default DashboardTheme;
