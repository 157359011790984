import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Avatar, Switch, Tooltip } from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import { getFeedNameFromAlias } from "../js/dashboardUtils";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Link from "@mui/material/Link";
import { Button, Snackbar } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";

import { AuthContext } from "../contexts/AuthProvider";
import { GlobalContext } from "../contexts/GlobalContextStore";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
    transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    bgcolor: "background.paper",
    p: 4,
    inlineSize: "min-content;",
};

export default function AppToolBar() {
    const [AuthState] = useContext(AuthContext);
    const [storeStates, storeDispatch] = useContext(GlobalContext);
    const [open, setOpen] = React.useState(false);
    const [snapShotLink, setSnapShotLink] = React.useState("");
    const [snackBarOpen, setSnackBarOpen] = React.useState(false);
    // const theme = useTheme();

    const handleDrawerOpen = () => {
        storeDispatch({ type: "OPEN_SIDEBAR", openSideBar: true });
    };

    const handleClose = () => setOpen(false);

    // Trigger toggle using onChange Switch
    const handleModeChange = () => {
        storeDispatch({
            type: "TOGGLE_DARK_THEME",
            toggleDark: !storeStates.toggleDark,
        });
    };

    const handleCopyClick = () => {
        setSnackBarOpen(true);
        navigator.clipboard.writeText(snapShotLink);
    };

    //Handle Snapshot share button
    const handleSnapShotShare = () => {
        //Get current state of feeds displayed and attributes
        const feeds = [];
        const attrs = [];
        let url = "";
        if (process.env.REACT_APP_API_ENVIRONMENT !== "Prod") {
            url = "https://gamma.streampreview.amazonavoc.com";
        } else {
            url = "https://prod.streampreview.amazonavoc.com";
        }
        for (const alias of storeStates.checkBoxSectionData) {
            feeds.push(getFeedNameFromAlias(alias, storeStates.dashboardFeedQueryData.dbQueryData[0]));
        }
        for (const [key, value] of Object.entries(storeStates.attributeState)) {
            if (value) {
                attrs.push(key);
            }
        }
        console.log(`${url}/params?feeds=${feeds.toString()}&&attributes=${attrs.toString()}`);
        setSnapShotLink(`${url}/params?feeds=${feeds.toString()}&&attributes=${attrs.toString()}`);
        setOpen(true);
    };

    return (
        <React.Fragment>
            <AppBar position="fixed" open={storeStates.openSideBar}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            mr: 2,
                            ...(storeStates.openSideBar && { display: "none" }),
                            color: (theme) =>
                                theme.palette.mode === "dark"
                                    ? theme.palette.primary.main
                                    : theme.palette.primary.contrastText,
                        }}
                    >
                        <Tooltip enterNextDelay={500} title="Open Feed selection menu" arrow>
                            <MenuIcon />
                        </Tooltip>
                    </IconButton>
                    <Tooltip enterNextDelay={500} title="Toggle Dark & Light theme" arrow>
                        <Switch
                            checked={storeStates.toggleDark}
                            onChange={handleModeChange}
                            name="toggleDark"
                            color="primary"
                        />
                    </Tooltip>

                    <IconButton
                        aria-label="share snapshot"
                        onClick={handleSnapShotShare}
                        edge="start"
                        sx={{
                            m: 2,
                            color: (theme) =>
                                theme.palette.mode === "dark"
                                    ? theme.palette.primary.main
                                    : theme.palette.primary.contrastText,
                        }}
                    >
                        <Tooltip enterNextDelay={500} title="Take dashboard snapshot to share via link" arrow>
                            <ShareIcon />
                        </Tooltip>
                    </IconButton>

                    <Typography
                        variant="h6"
                        // color="inherit"
                        align="center"
                        noWrap
                        sx={{
                            flexGrow: 1,
                            color: (theme) =>
                                theme.palette.mode === "dark"
                                    ? theme.palette.primary.main
                                    : theme.palette.primary.contrastText,
                        }}
                    >
                        Encoder Thumbnail Dashboard
                    </Typography>
                    <Typography mr={2}>{AuthState.user}</Typography>
                    {/* <Avatar alt="username"  src="./mui.png" /> */}
                    <Avatar
                        alt="username"
                        src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${AuthState.user}`}
                    />
                </Toolbar>
            </AppBar>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: "center" }}>
                        {"Dashboard Snapshot Link"}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ m: 2 }}>
                        <Link href={snapShotLink} underline="hover">
                            {snapShotLink}
                        </Link>
                    </Typography>
                    <DialogActions sx={{ m: 2 }}>
                        <Tooltip title="Copy to ClipBoard" arrow>
                            <Button variant="outlined" onClick={handleCopyClick} sx={{ mr: 4 }}>
                                Copy
                            </Button>
                        </Tooltip>

                        <Snackbar
                            open={snackBarOpen}
                            onClose={() => setSnackBarOpen(false)}
                            autoHideDuration={1000}
                            message="Copied to clipboard"
                        />
                        <Tooltip title="close this message" arrow>
                            <Button variant="outlined" onClick={handleClose}>
                                Close
                            </Button>
                        </Tooltip>
                    </DialogActions>
                </Box>
            </Modal>
        </React.Fragment>
    );
}
