import { API } from "aws-amplify";

const requestHeaders = {
    "Cache-Control": "no-store",
    // "Content-type": "application/json",
    "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Cache-Control, Content-Type, Access-Control-Allow-Origin, Access-Control-Allow-Headers, Authorization, X-Requested-With"
}

const getZixiOutputId = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing  API.get for getEventXML on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getZixiOutputId function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_zixi_output_id",
            ...params,
        },
    }).catch(handleError);
    const data = await response;
    return data;
};

const getZixiSysLoads = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing  API.get for getEventlog on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getZixiSysLoads function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_zixi_sys_loads",
            ...params,
        },
    }).catch(handleError);
    const data = await response;
    return data;
};

const getZixiInputStreamStats = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getSCTElog on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getZixiInputStreamStats function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_zixi_input_stream_stats",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getZixiOutputStreamStats = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getActiveAlertsCount on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getZixiOutputStreamStats function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_zixi_output_stream_stats",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getZixiThumbnailData = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getThumbnailData on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getZixiThumbnailData function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_zixi_thumbnail_binary",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getZixiThumbnailUrl = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getThumbnailUrl on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getZixiThumbnailUrl function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_zixi_thumbnail_url",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getZixiInputId = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getEventId on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getZixiInputId function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers:requestHeaders,
        queryStringParameters: {
            action: "get_zixi_input_id",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getZixiStreamConfig = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getStreamConfig on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing ZixiStreamConfig function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_zixi_stream_config",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

export {
    getZixiStreamConfig,
    getZixiInputId,
    getZixiOutputId,
    getZixiSysLoads,
    getZixiThumbnailUrl,
    getZixiThumbnailData,
    getZixiInputStreamStats,
    getZixiOutputStreamStats,
};
