import React, { useContext } from "react";
import { styled, ThemeProvider } from "@mui/material/styles";
import { Box, Card, CardActions, CardContent, Grid, IconButton, Tooltip } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";

import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fade from "@mui/material/Fade";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import PropTypes from "prop-types";
import { GlobalContext } from "../contexts/GlobalContextStore";

import AppToolBar from "../components/AppToolBar";
import AppFooter from "../components/AppFooter";
import DrawerHeader from "../components/DrawerHeader";
import DashboardTheme from "../components/DashboardTheme";
import StreamViewer from "../components/StreamViewer";
import LeftMenuDrawer from "../components/LeftMenuDrawer";
import { Container } from "@mui/system";
import { useFeedDataApiQuery } from "../js/useFeedDataQueryUtils";

const drawerWidth = 240;

function ScrollTop(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    /**
     * handle scroll to top button click
     * @param {*} event
     */
    const handleScrollToTopButtonClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector("#scroll-to-here");

        if (anchor) {
            anchor.scrollIntoView({
                block: "center",
            });
        }
    };

    return (
        <Fade in={trigger}>
            <Box
                onClick={handleScrollToTopButtonClick}
                role="presentation"
                sx={{ position: "fixed", bottom: 16, right: 16 }}
            >
                {children}
            </Box>
        </Fade>
    );
}

ScrollTop.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

/**
 * Styled main component.
 */
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(1),
    transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create("margin", {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

/**
 * Dashboard component
 * @param {*} props
 * @returns {JSX} JSX object
 */
export default function Dashboard(props) {
    const [storeStates, storeDispatch] = useContext(GlobalContext);
    const useFeedDataApiQueryResultObj = useFeedDataApiQuery();

    /**
     * handles close show SCTE events. updates global states.
     */
    const closeShowScteEvents = () => {
        storeDispatch({ type: "SHOW_SCTE_EVENTS", showScteEvents: false });
        storeDispatch({ type: "SET_SCTE_DATA", scteData: {} });
    };

    React.useEffect(() => {
        // console.log("Got useFeedDataApiQueryResultObj.data:"); //debug aam
        // console.log(useFeedDataApiQueryResultObj.data); //debug aam
        storeDispatch({
            type: "GET_DASHBOARD_FEED_QUERY_DATA",
            dashboardFeedQueryData: {
                dbQueryData: useFeedDataApiQueryResultObj.data,
                isDbQueryLoading: useFeedDataApiQueryResultObj.isLoading,
                isDbQueryError: useFeedDataApiQueryResultObj.isError,
                dbQueryErrorMsg: useFeedDataApiQueryResultObj.error,
            },
        });
    }, [
        storeDispatch,
        useFeedDataApiQueryResultObj.data,
        useFeedDataApiQueryResultObj.isLoading,
        useFeedDataApiQueryResultObj.isError,
        useFeedDataApiQueryResultObj.error,
    ]);

    return (
        <ThemeProvider theme={DashboardTheme(storeStates.toggleDark)}>
            <React.Fragment>
                {/* Scroll back to  this Div */}
                <div sx={{ display: "none", visibility: "hidden" }} id="scroll-to-here">
                    {" "}
                </div>

                {/* Main Dashboard Section */}
                <Container maxWidth="xxl">
                    {/* <Container maxWidth="98vw"> */}
                    <CssBaseline />

                    {/* Top Appbar / Header */}
                    <AppToolBar />

                    <Box sx={{ display: "flex" }}>
                        {/*  Left Drawer Menu */}
                        <LeftMenuDrawer />

                        <Main open={storeStates.openSideBar}>
                            {/*  Need this for alignment */}
                            <DrawerHeader />

                            <Grid
                                container
                                justifyContent="center"
                                rowSpacing={2}
                                columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 4, xl: 4 }}
                                sx={{ flexGrow: 1 }}
                            >
                                {storeStates.dashboardFeedQueryData.isDbQueryLoading ? (
                                    <Box
                                        marginTop={4}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "inherit",
                                            // border: "1px solid blue",
                                        }}
                                    >
                                        <CircularProgress size="10vh" color="inherit" />
                                    </Box>
                                ) : (
                                    storeStates.showFeedData && <StreamViewer />
                                )}
                                {/* {storeStates.showFeedData && <StreamViewer />} */}
                            </Grid>
                            {/* SCTE35 Data Pane */}
                            {storeStates.showScteEvents && (
                                <Grid
                                    container
                                    justifyContent="center"
                                    spacing={1}
                                    marginTop={2}
                                    sx={{ position: "left" }}
                                >
                                    <Card maxWidth="inherit">
                                        <CardActions>
                                            <IconButton onClick={() => closeShowScteEvents()}>
                                                <Tooltip title="Close this pane" arrow>
                                                    <CloseIcon
                                                        sx={{
                                                            color: (theme) =>
                                                                theme.palette.mode === "dark"
                                                                    ? theme.palette.primary.main
                                                                    : theme.palette.primary.main,
                                                        }}
                                                    />
                                                </Tooltip>
                                            </IconButton>
                                            <Typography variant="h8" display="block" ml={4}>
                                                SCTE35 Events: {storeStates.scteData.feed_name}
                                            </Typography>
                                        </CardActions>
                                        <CardContent>
                                            <div>
                                                {"scte_events" in storeStates.scteData ? (
                                                    storeStates.scteData.scte_events.map((item, idx) => (
                                                        <code key={idx} sx={{ fontSize: "0.6rem" }}>
                                                            {item} <br />
                                                        </code>
                                                    ))
                                                ) : (
                                                    <Box sx={{ display: "flex" }}>
                                                        {" "}
                                                        <CircularProgress />{" "}
                                                    </Box>
                                                )}
                                            </div>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}

                            {/* Footer */}
                            {storeStates.attributeState.show_footer && (
                                <Grid container justifyContent="center" mt={4} spacing={2} sx={{ flexGrow: 1 }}>
                                    <AppFooter />
                                </Grid>
                            )}
                        </Main>
                    </Box>
                </Container>
                <ScrollTop {...props}>
                    <Fab size="small" aria-label="scroll back to top">
                        <Tooltip title="Jump Back to Top" arrow>
                            <KeyboardArrowUpIcon />
                        </Tooltip>
                    </Fab>
                </ScrollTop>
            </React.Fragment>
        </ThemeProvider>
    );
}
