import React, { useEffect, useContext } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import jwt from "jwt-decode";
import { Auth, Hub } from "aws-amplify";

import Dashboard from "./pages/Dashboard";
import { AuthContext } from "./contexts/AuthProvider";
import { GlobalContext } from "./contexts/GlobalContextStore";
import { Login } from "./components/Login";
import { NotFoundPage } from "./pages/InValidPath";
import { WebUrlParamsHandler } from "./components/WebUrlParamsHandler";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const RedirectOnLogin = () => {
    const [storeStates, storeDispatch] = useContext(GlobalContext);
    const redirectData = JSON.parse(localStorage.getItem("url"));
    const navigate = useNavigate();

    useEffect(() => {
        storeDispatch({
            type: "SET_FROM_LOGIN_PAGE",
            fromLoginPage: false,
        });
        storeDispatch({
            type: "SET_URL_DATA",
            urlData: null,
        });

        localStorage.setItem("url", null); //clear url data

        if (typeof redirectData === "object" && redirectData !== null && redirectData !== undefined) {
            navigate({
                pathname: redirectData.pathname,
                search: redirectData.search,
            });
        } else {
            navigate({
                pathname: "/",
                search: "",
            });
        }
    }, []);
    return (
        <>
            <h4> Redirecting .......</h4>
        </>
    );
};

function App() {
    const [AuthState, AuthDispatch] = useContext(AuthContext);
    const [storeStates, storeDispatch] = useContext(GlobalContext);
    const queryClient = new QueryClient();

    useEffect(() => {
        let user = localStorage.getItem("user") || null;
        let token = localStorage.getItem("token") || null;

        if (user && token) {
            //Check token validity. Relogin if necessary
            let userTokenExpEpochDate = jwt(token).exp;
            let currentEpochDate = Math.round(Date.now() / 1000);
            if (currentEpochDate >= userTokenExpEpochDate) {
                //Token Expired
                console.log("Token expired, Signout in progress");
                Auth.signOut();
                localStorage.clear();
                token = null;
                user = null;
                AuthDispatch({
                    type: "LOGOUT",
                    payload: {
                        user,
                        token,
                    },
                });
            } else {
                //token valid
                console.log("Existing Token valid, Sigin in progress");
                AuthDispatch({
                    type: "LOGIN",
                    payload: {
                        user,
                        token,
                    },
                });
            }
        }

        //exit cleanup function
        return () => {
            AuthDispatch({
                type: "LOGOUT",
                payload: {
                    user,
                    token,
                },
            });
        };
    }, []);

    React.useEffect(() => {
        Hub.listen("auth", ({ payload: { event, data } }) => {
            console.dir(process.env);

            switch (event) {
                case "signIn":
                    console.log("signIn event in progress");
                    break;

                case "cognitoHostedUI":
                    console.log("In cognitoHostedUI");
                    // console.dir(data); //debug aam

                    Auth.currentSession().then((session) => {
                        console.dir(session);
                        const user = session.idToken.payload.identities[0].userId || null;
                        const token = session.accessToken.jwtToken;

                        //Check token validity. Relogin if necessary
                        let userTokenExpEpochDate = jwt(token).exp;
                        let currentEpochDate = Math.round(Date.now() / 1000);
                        console.log(
                            `currentEpochDate = ${currentEpochDate} ; userTokenExpEpochDate = ${userTokenExpEpochDate}`
                        );
                        if (currentEpochDate >= userTokenExpEpochDate) {
                            //Token Expired
                            console.log("Token expired, Signout event in progress");
                            Auth.signOut();
                            localStorage.clear();
                            let token = null;
                            let user = null;
                            AuthDispatch({
                                type: "LOGOUT",
                                payload: {
                                    user,
                                    token,
                                },
                            });
                        } else {
                            //token valid (after redirection from cognito)
                            console.log("New Token valid, Sigin in event progress");

                            storeDispatch({
                                type: "SET_FROM_LOGIN_PAGE",
                                fromLoginPage: true,
                            });
                            AuthDispatch({
                                type: "LOGIN",
                                payload: {
                                    user,
                                    token,
                                },
                            });
                        }
                    });
                    break;

                case "signOut":
                    console.log("signOut event in progress");
                    localStorage.clear();
                    let token = null;
                    let user = null;
                    AuthDispatch({
                        type: "LOGOUT",
                        payload: {
                            user,
                            token,
                        },
                    });
                    break;

                case "oAuthSignOut":
                    console.log("oAuthSignOut event in progress");
                    localStorage.clear();
                    token = null;
                    user = null;
                    AuthDispatch({
                        type: "LOGOUT",
                        payload: {
                            user,
                            token,
                        },
                    });
                    break;

                case "signIn_failure":
                    console.log("signIn_failure event in progress");
                    localStorage.clear();
                    token = null;
                    user = null;
                    AuthDispatch({
                        type: "LOGOUT",
                        payload: {
                            user,
                            token,
                        },
                    });
                    break;

                case "cognitoHostedUI_failure":
                    console.log("cognitoHostedUI_failure event in progress");
                    localStorage.clear();
                    token = null;
                    user = null;
                    AuthDispatch({
                        type: "LOGOUT",
                        payload: {
                            user,
                            token,
                        },
                    });
                    break;

                default:
                    console.log("In Hub Default option.");
            }
        });
    }, []);

    return (
        <React.Fragment>
            <BrowserRouter>
                {AuthState.isAuthenticated === false ? (
                    <Login />
                ) : (
                    <>
                        {storeStates.fromLoginPage &&
                        (localStorage.getItem("url") !== null || localStorage.getItem("url") !== undefined) ? (
                            <RedirectOnLogin />
                        ) : (
                            <>
                                <QueryClientProvider client={queryClient}>
                                    <Routes>
                                        <Route exact path="/" element={<Dashboard />} />
                                        <Route path="params" element={<WebUrlParamsHandler />} />
                                        <Route path="*" element={<NotFoundPage />} />
                                    </Routes>
                                </QueryClientProvider>
                            </>
                        )}
                    </>
                )}
            </BrowserRouter>
        </React.Fragment>
    );
}

export default App;
