import { API } from "aws-amplify";

const requestHeaders = {
    "Cache-Control": "no-store",
    // "Content-type": "application/json",
    "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Cache-Control, Content-Type, Access-Control-Allow-Origin, Access-Control-Allow-Headers, Authorization, X-Requested-With"
}

const getEventXML = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing  API.get for getEventXML on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getEventXML function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers:requestHeaders,
        queryStringParameters: {
            action: "get_event_xml",
            ...params,
        },
    }).catch(handleError);
    const data = await response;
    return data;
};

const getEventlog = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing  API.get for getEventlog on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getEventlog function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_event_log",
            ...params,
        },
    }).catch(handleError);
    const data = await response;
    return data;
};

const getSCTElog = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getSCTElog on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getSCTElog function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_scte_log",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getActiveAlertsCount = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getActiveAlertsCount on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getActiveAlerts function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_alerts_count",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getThumbnailData = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getThumbnailData on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getThumbnailData function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_encoder_thumbnail_binary",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getThumbnailUrl = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getThumbnailUrl on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getThumbnailUrl function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            // action: "get_encoder_thumbnail_url",
            action: "get_encoder_thumbnail_s3",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getEventStatus = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getEventStatus on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getFeedStatusData function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_event_status_data",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getHardwareStatus = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getHardwareStatus on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getFeedStatusData function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_hardware_status_data",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getFeedStatus = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getFeedStatus on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getFeedStatusData function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_feed_status_data",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getEventId = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getEventId on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getEventId function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers: requestHeaders,
        queryStringParameters: {
            action: "get_event_id",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

const getStreamConfig = async (params) => {
    const path = "encoder_dashboard_api/read";
    // console.log(`Executing API.get for getStreamConfig on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

    var handleError = function (err) {
        console.warn(err);
        return new Response(
            JSON.stringify({
                code: 400,
                message: "Error executing getEventFullDetails function",
            })
        );
    };

    const response = await API.get(process.env.REACT_APP_API_ENVIRONMENT, path, {
        headers:requestHeaders,
        queryStringParameters: {
            action: "get_stream_config",
            ...params,
        },
    }).catch(handleError);

    const data = await response;
    return data;
};

export {
    getStreamConfig,
    getEventId,
    getFeedStatus,
    getEventStatus,
    getHardwareStatus,
    getThumbnailUrl,
    getThumbnailData,
    getActiveAlertsCount,
    getSCTElog,
    getEventlog,
    getEventXML,
};
