//import { menuData } from "./feedData";
import { regionList } from "./appConstants";
// import { useFeedDataApiQuery as menuData } from "./useFeedDataQueryUtils";

/**
 * Gets stream link
 * @param {*} data Object
 * @returns {object} Object and encoder URL link
 */
const getStreamLink = (data) => {
    if (data !== undefined && data[0] !== undefined) {
        let thisStreamLiveServiceEndpoint = data[0].live_service_endpoint ? data[0].live_service_endpoint : undefined;
        let thisStreamEventId = data[0].event_id ? data[0].event_id : undefined;
        let thisHref =
            thisStreamLiveServiceEndpoint && thisStreamEventId
                ? thisStreamLiveServiceEndpoint + "/live_events/" + thisStreamEventId
                : "#";
        let thisLink =
            thisStreamLiveServiceEndpoint && thisStreamEventId
                ? `${thisStreamLiveServiceEndpoint.split("//")[1].split(".").slice(0, -2).join(".")}` +
                  "/live_events/" +
                  thisStreamEventId
                : "undefined";
        return {
            thisHref: thisHref,
            thisLink: thisLink,
        };
    } else {
        return {
            thisHref: "#",
            thisLink: "undefined",
        };
    }
};

const getStreamEventNameAndId = (data) => {
    let thisStreamEventName = "undefined";
    let thisStreamEventId = "undefined";

    if (data !== undefined && data[0] !== undefined) {
        thisStreamEventName = data[0].event_name ? data[0].event_name : "undefined";
        thisStreamEventId = data[0].event_id ? data[0].event_id : "undefined";
    }
    return {
        thisStreamEventName: thisStreamEventName,
        thisStreamEventId: thisStreamEventId,
    };
};

/**
 * Gets stream uptime
 * @param {*} data object
 * @returns {object} object with stream uptime
 */
const getStreamUpTime = (data) => {
    let thisStreamUpTime = "undefined";

    if (data !== undefined && data[0] !== undefined) {
        if (data[0].event_run_status !== undefined) {
            thisStreamUpTime = data[0].event_run_status.uptime ? data[0].event_run_status.uptime : "undefined";
        }
    }
    return {
        thisStreamUpTime: thisStreamUpTime,
    };
};

/**
 * Gets stream frame rate
 * @param {*} data object
 * @returns {object} object with stream frame rate
 */
const getStreamFrameRate = (data) => {
    let thisStreamFrameRate = "undefined";

    if (data !== undefined && data[0] !== undefined) {
        if (data[0].event_run_status !== undefined) {
            thisStreamFrameRate = data[0].event_run_status.video_framerate
                ? data[0].event_run_status.video_framerate
                : "undefined";
        }
    }
    return {
        thisStreamFrameRate: thisStreamFrameRate,
    };
};

/**
 * Gets streams current active input
 * @param {*} data object
 * @returns {object} object with stream input and uri
 */
const getStreamActiveInput = (data) => {
    let thisStreamInput = "undefined";
    let thisStreamUri = "undefined";

    if (data !== undefined && data[0] !== undefined) {
        if (data[0].event_run_status !== undefined) {
            thisStreamInput = data[0].event_run_status.input ? data[0].event_run_status.input : "undefined";
            thisStreamUri = data[0].event_run_status.input_uri ? data[0].event_run_status.input_uri : "undefined";
        }
    }
    return {
        thisStreamInput: thisStreamInput,
        thisStreamUri: thisStreamUri,
    };
};

/**
 * Gets stream audio level
 * @param {*} data object
 * @returns {object} object with stream audio level
 */
const getStreamAudioLevel = (data) => {
    let thisStreamAudioLevel = "undefined";
    if (data !== undefined && data[0] !== undefined) {
        if (data[0].event_run_status !== undefined) {
            thisStreamAudioLevel = data[0].event_run_status.audio_level
                ? data[0].event_run_status.audio_level
                : "undefined";
        }
    }
    return {
        thisStreamAudioLevel: thisStreamAudioLevel,
    };
};

/**
 * Gets stream active alerts
 * @param {*} data object
 * @returns {object} object with active stream alert
 */
const getStreamActiveAlerts = (data) => {
    let thisStreamActiveAlerts = "undefined";
    if (data !== undefined && data[0] !== undefined) {
        thisStreamActiveAlerts = data[0].active_alerts ? data[0].active_alerts : "undefined";
    }
    return {
        thisStreamActiveAlerts: thisStreamActiveAlerts,
    };
};

/**
 * Gets stream hardware status
 * @param {*} data object
 * @returns {object} object with encoder cpu, mem, disk, gpu 1,2,3,4
 */
const getStreamHwStatus = (data) => {
    let thisStreamCpu = " - ";
    let thisStreamMem = " - ";
    let thisStreamDisk = " - ";
    let thisStreamGpu1 = " - ";
    let thisStreamGpu2 = " - ";
    let thisStreamGpu3 = " - ";
    let thisStreamGpu4 = " - ";

    if (data !== undefined && data[0] !== undefined) {
        if (data[0].hardware_status !== undefined) {
            thisStreamCpu = data[0].hardware_status.cpu ? data[0].hardware_status.cpu : " - ";
            thisStreamMem = data[0].hardware_status.mem ? data[0].hardware_status.mem : " - ";
            thisStreamDisk = data[0].hardware_status.disk_available ? data[0].hardware_status.disk_available : " - ";
            thisStreamGpu1 = data[0].hardware_status.gpu1 ? data[0].hardware_status.gpu1 : " - ";
            thisStreamGpu2 = data[0].hardware_status.gpu2 ? data[0].hardware_status.gpu2 : " - ";
            thisStreamGpu3 = data[0].hardware_status.gpu3 ? data[0].hardware_status.gpu3 : " - ";
            thisStreamGpu4 = data[0].hardware_status.gpu4 ? data[0].hardware_status.gpu4 : " - ";
        }
    }
    return {
        thisStreamCpu: thisStreamCpu,
        thisStreamMem: thisStreamMem,
        thisStreamDisk: thisStreamDisk,
        thisStreamGpu1: thisStreamGpu1,
        thisStreamGpu2: thisStreamGpu2,
        thisStreamGpu3: thisStreamGpu3,
        thisStreamGpu4: thisStreamGpu4,
    };
};

/**
 * Gets stream audio normalization
 * @param {*} data object
 * @returns {object} object with streams audio norm
 */
const getStreamAudioNorm = (data) => {
    let thisStreamAudioNorm = "undefined";

    if (data !== undefined && data[0] !== undefined) {
        if (data[0].config_status !== undefined && data[0].config_status.audio_normalization !== undefined) {
            thisStreamAudioNorm = data[0].config_status.audio_normalization ? "On" : "Off";
        }
    }
    return {
        thisStreamAudioNorm: thisStreamAudioNorm,
    };
};

/**
 * Gets streams thumbnail url
 * @param {*} data object
 * @returns {object} streams signed thumbnail url
 */
const getStreamThumbnailData = (data) => {
    let thisStreamThumbnailData = "undefined";

    if (data !== undefined && data[0] !== undefined) {
        thisStreamThumbnailData = data[0].binary_image ? data[0].binary_image : "undefined";
    }
    return {
        thisStreamThumbnailData: thisStreamThumbnailData,
    };
};

/**
 * Gets stream names from feed selection.
 * @param {*} feed
 * @returns
 */
const getStreamsFromAlias = (feed, menuData) => {
    //check if input is feed or stream
    const isStream = regionList.includes(feed.split("-").slice(-2).join("-"));
    if (isStream) {
        return [feed];
    } else {
        //Input is feed, find its streams in menuData
        //given a feed get its streams and map regions to each stream.
        let thisStreamsArray = [];
        let thisFeedData = {};
        let value = [];
        for (const tenant in menuData) {
            value = menuData[tenant].filter((rec) => rec.alias === feed);
            if (value.length > 0) thisFeedData = { ...value[0] };
        }
        const thisFeedDataFeeds = thisFeedData.feeds;
        const thisFeedDataRegions = thisFeedData.regions;
        thisFeedDataFeeds.split(",").map((d) =>
            thisFeedDataRegions.split(",").forEach((r) => {
                if (!thisStreamsArray.includes(`${d}-${r}-1`)) thisStreamsArray.push(`${d}-${r}-1`);
                if (!thisStreamsArray.includes(`${d}-${r}-2`)) thisStreamsArray.push(`${d}-${r}-2`);
            })
        );
        return thisStreamsArray;
    }
};

/**
 * Check if given value is feed
 * @param {*} feed / stream
 * @returns { Boolean}
 */
const isFeed = (stringToCheck) => {
    //check if input is feed or stream
    const isStream = regionList.includes(stringToCheck.split("-").slice(-2).join("-"));
    return isStream ? false : true;
};

/**
 * Gets stream names from feed selection.
 * @param {*} feed
 * @returns {string} of feeds
 */
const getFeedNameFromAlias = (alias, menuData) => {
    //check if input is feed or stream
    //Input is feed, find its streams in menuData
    //given a feed get its streams and map regions to each stream.
    let thisFeedData = {};
    let value = [];
    for (const tenant in menuData) {
        value = menuData[tenant].filter((rec) => rec.alias === alias);
        if (value.length > 0) thisFeedData = { ...value[0] };
    }
    return thisFeedData.feeds;
};

/**
 * Get archType from feedlist
 * @param {*} feed
 * @returns arcType: hw_encoder or zixi
 */
const getArchTypeFromAlias = (alias, menuData) => {
    //check if input is feed or stream
    //Input is feed, find its archType in menuData from alias
    let archTypeData = {};
    let value = [];
    for (const tenant in menuData) {
        value = menuData[tenant].filter((rec) => rec.alias === alias);
        if (value.length > 0) archTypeData = { ...value[0] };
    }
    return archTypeData.archType;
};

/**
 * Get archType from menuData
 * @param {*} stream
 * @returns arcType: hw_encoder or zixi
 */
const getArchTypeFromStream = (stream, menuData) => {
    let archTypeData = {};
    let value = [];
    for (const tenant in menuData) {
        value = menuData[tenant].filter((rec) => rec.feeds.includes(stream.split("-")[0]));
        if (value.length > 0) archTypeData = { ...value[0] };
    }
    return archTypeData.archType;
};

/**
 * Get archType from menuData
 * @param {*} stream
 * @returns arcType: hw_encoder or zixi
 */
const getAliasFromFeedName = (feedName, menuData) => {
    for (const tenant in menuData) {
        let filterData = menuData[tenant].filter((rec) => rec.feeds.split(",").indexOf(feedName) !== -1);
        if (filterData.length > 0) {
            return filterData[0].alias;
        }
    }
    return undefined;
};

export {
    getStreamLink,
    getStreamEventNameAndId,
    getStreamUpTime,
    getStreamFrameRate,
    getStreamActiveInput,
    getStreamAudioLevel,
    getStreamActiveAlerts,
    getStreamHwStatus,
    getStreamAudioNorm,
    getStreamThumbnailData,
    getStreamsFromAlias,
    isFeed,
    getFeedNameFromAlias,
    getArchTypeFromAlias,
    getArchTypeFromStream,
    getAliasFromFeedName,
};
