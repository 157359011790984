import React, { useContext } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { GlobalContext } from "../contexts/GlobalContextStore";

const WebUrlAttrHandler = () => {
    const [storeStates, storeDispatch] = useContext(GlobalContext);
    React.useEffect(() => {
        if (storeStates.urlParamsAttrList.length > 0) {
            for (const attr of storeStates.urlParamsAttrList) {
                if (attr === "showCustomLabelField") {
                    //Set showCustomLabelField to true
                    storeDispatch({
                        type: "SET_ATTRIBUTE_STATE",
                        newAttributeState: { showCustomLabelField: true },
                    });
                    //Also set showFooter to false
                    storeDispatch({
                        type: "SET_ATTRIBUTE_STATE",
                        newAttributeState: { show_footer: false },
                    });
                } else {
                    storeDispatch({
                        type: "SET_ATTRIBUTE_STATE",
                        newAttributeState: { [attr]: true },
                    });
                }
            }
        }
    }, []);

    return <></>;
};

export default function AttributesSelector() {
    const [storeStates, storeDispatch] = React.useContext(GlobalContext);

    const handleChange = (event) => {
        if (event.target.name === "showCustomLabelField") {
            //Set showCustomLabelField to true
            storeDispatch({
                type: "SET_ATTRIBUTE_STATE",
                newAttributeState: { [event.target.name]: event.target.checked },
            });
            //Also set showFooter to false
            storeDispatch({
                type: "SET_ATTRIBUTE_STATE",
                newAttributeState: { show_footer: false },
            });
        } else {
            storeDispatch({
                type: "SET_ATTRIBUTE_STATE",
                newAttributeState: { [event.target.name]: event.target.checked },
            });
        }
    };

    return (
        <React.Fragment>
            <WebUrlAttrHandler />
            <Box sx={{ display: "flex" }}>
                <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                    <FormGroup>
                        <FormControlLabel
                            componentsProps={{ typography: { variant: "caption" } }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={storeStates.attributeState.showCustomLabelField}
                                    onChange={handleChange}
                                    name="showCustomLabelField"
                                />
                            }
                            label="Custom Label Field"
                        />
                        <FormControlLabel
                            componentsProps={{ typography: { variant: "caption" } }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={storeStates.attributeState.encoder_link}
                                    onChange={handleChange}
                                    name="encoder_link"
                                />
                            }
                            label="Encoder Link"
                        />
                        <FormControlLabel
                            componentsProps={{ typography: { variant: "caption" } }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={storeStates.attributeState.encoder_event}
                                    onChange={handleChange}
                                    name="encoder_event"
                                />
                            }
                            label="Encoder Event"
                        />
                        <FormControlLabel
                            componentsProps={{ typography: { variant: "caption" } }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={storeStates.attributeState.event_stats}
                                    onChange={handleChange}
                                    name="event_stats"
                                />
                            }
                            label="Event Stats"
                        />
                        <FormControlLabel
                            componentsProps={{ typography: { variant: "caption" } }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={storeStates.attributeState.encoder_stats}
                                    onChange={handleChange}
                                    name="encoder_stats"
                                />
                            }
                            label="Encoder Stats"
                        />
                        <FormControlLabel
                            componentsProps={{ typography: { variant: "caption" } }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={storeStates.attributeState.active_alerts}
                                    onChange={handleChange}
                                    name="active_alerts"
                                />
                            }
                            label="Active Alerts"
                        />
                        <FormControlLabel
                            componentsProps={{ typography: { variant: "caption" } }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={storeStates.attributeState.image_thumbnail}
                                    onChange={handleChange}
                                    name="image_thumbnail"
                                />
                            }
                            label="Image Thumbnail"
                        />
                        <FormControlLabel
                            componentsProps={{ typography: { variant: "caption" } }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={storeStates.attributeState.download_buttons}
                                    onChange={handleChange}
                                    name="download_buttons"
                                />
                            }
                            label="SCTE / Logs / XML Download Buttons"
                        />
                        <FormControlLabel
                            componentsProps={{ typography: { variant: "caption" } }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={storeStates.attributeState.progress_bar}
                                    onChange={handleChange}
                                    name="progress_bar"
                                />
                            }
                            label="Progress Bar"
                        />
                        <FormControlLabel
                            componentsProps={{ typography: { variant: "caption" } }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={storeStates.attributeState.show_footer}
                                    onChange={handleChange}
                                    name="show_footer"
                                />
                            }
                            label="Show / Hide Footer"
                        />
                    </FormGroup>
                </FormControl>
            </Box>
        </React.Fragment>
    );
}
