import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

export const CustomLabelCard = (props) => {
    return (
        <Box noValidate autoComplete="off">
            <Card>
                <CardContent>
                    <Typography variant="h6" display="flex" sx={{ color: "#00EE00", fontWeight: "bold" }}>
                        {props.feedName}
                    </Typography>

                    <div>
                        <TextField
                            id="outlined-multiline-static"
                            multiline
                            fullWidth
                            rows={4}
                            InputProps={{
                                style: { fontSize: "0.8vw" },
                            }}
                        />
                    </div>
                </CardContent>
            </Card>
        </Box>
    );
};
