import React, { useContext } from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { IconButton, Grid, Toolbar, Tooltip } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";

//Local imports
import { FeedSelectionOptions } from "./FeedSelectionOptions";
import FeedInputForm from "./FeedInputForm";
import AttributesSelector from "./AttributesSelector";
import { GlobalContext } from "../contexts/GlobalContextStore";

const drawerWidth = 260;

export default function LeftMenuDrawer() {
    const [storeStates, storeDispatch] = useContext(GlobalContext);

    const closeSideMenu = () => {
        storeDispatch({ type: "OPEN_SIDEBAR", openSideBar: false });
    };

    return (
        <React.Fragment>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: drawerWidth,
                        boxSizing: "border-box",
                    },
                }}
                variant="persistent"
                anchor="left"
                open={storeStates.openSideBar}
            >
                <Toolbar
                    sx={{
                        width: "100%",
                        backgroundColor: storeStates.toggleDark ? "rgba(255, 255, 255, 0.09)" : "#FF9900",
                    }}
                >
                    <Grid container justifyContent="flex-end">
                        <Typography
                            variant="subtitle1"
                            align="left"
                            mt={1}
                            noWrap
                            sx={{
                                flexGrow: 1,
                                color: (theme) =>
                                    theme.palette.mode === "dark"
                                        ? theme.palette.primary.main
                                        : theme.palette.primary.contrastText,
                            }}
                        >
                            Selections Menu
                        </Typography>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={closeSideMenu}
                            sx={{
                                mr: -2,
                                float: "right",
                                color: (theme) =>
                                    theme.palette.mode === "dark"
                                        ? theme.palette.primary.main
                                        : theme.palette.primary.contrastText,
                            }}
                        >
                            <Tooltip enterNextDelay={2000} title="close this menu" arrow>
                                <CloseTwoToneIcon />
                            </Tooltip>
                        </IconButton>
                    </Grid>
                </Toolbar>
                <Divider />

                {/*  Accordian Test */}
                <Accordion elevation={1} disableGutters>
                    <AccordionSummary
                        sx={{ marginLeft: "10px" }}
                        expandIcon={
                            <Tooltip enterNextDelay={2000} title="Open / close feed selections" arrow>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        id="feed-select-header"
                    >
                        {/* Select Feeds */}
                        <Typography sx={{ fontSize: 14, marginBottom: "-10px" }}>Select Feeds</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ fontSize: "10px" }}>
                        <FeedInputForm />
                    </AccordionDetails>
                    <AccordionDetails sx={{ marginTop: "0px", marginBottom: "20px" }}>
                        <FeedSelectionOptions />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={
                            <Tooltip enterNextDelay={2000} title="Open / close attribute selections" arrow>
                                <ExpandMoreIcon />
                            </Tooltip>
                        }
                        id="attribute-select-header"
                    >
                        <Typography mt={2} sx={{ fontSize: 14 }}>
                            Select Attributes
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <AttributesSelector />
                    </AccordionDetails>
                </Accordion>
            </Drawer>
        </React.Fragment>
    );
}
