/**
 * Gets stream link
 * @param {*} data Object
 * @returns {object} Object and encoder URL link
 */
const getZixiStreamLink = (data) => {
    if (data !== undefined && data[0] !== undefined) {
        let thisStreamLiveServiceEndpoint = data[0].live_service_endpoint ? data[0].live_service_endpoint : undefined;
        let thisHref = thisStreamLiveServiceEndpoint ? thisStreamLiveServiceEndpoint : "#";
        let thisLink = thisStreamLiveServiceEndpoint
            ? `${thisStreamLiveServiceEndpoint.split("//")[1].split(".").slice(0, -2).join(".")}`
            : "undefined";
        return {
            thisHref: thisHref,
            thisLink: thisLink,
        };
    } else {
        return {
            thisHref: "#",
            thisLink: "undefined",
        };
    }
};

const getZixiStreamEventNameAndId = (data) => {
    let thisStreamEventNameAndId = "N/A";

    return {
        thisStreamEventNameAndId: thisStreamEventNameAndId,
    };
};

/**
 * Gets stream uptime
 * @param {*} data object
 * @returns {object} object with stream uptime
 */
const getZixiStreamUpTime = (data) => {
    let thisStreamUpTime = "undefined";

    if (data !== undefined && data[0] !== undefined) {
        if (data[0].input_stream_stats !== undefined) {
            thisStreamUpTime = data[0].input_stream_stats.up_time ? data[0].input_stream_stats.up_time : "undefined";
        }
    }
    return {
        thisStreamUpTime: thisStreamUpTime,
    };
};

/**
 * Gets stream input bitrate
 * @param {*} data object
 * @returns {object} object with input bitrate
 */
const getZixiStreamInputBitrate = (data) => {
    let thisInputBitrate = "undefined";

    if (data !== undefined && data[0] !== undefined) {
        if (data[0].input_stream_stats !== undefined) {
            if (data[0].input_stream_stats.net != undefined) {
                if (data[0].input_stream_stats.net.bitrate != undefined) {
                    thisInputBitrate = data[0].input_stream_stats.net.bitrate
                        ? data[0].input_stream_stats.net.bitrate
                        : "undefined";
                }
            }
        }
    }
    return {
        thisInputBitrate: thisInputBitrate,
    };
};

/**
 * Gets streams current active input
 * @param {*} data object
 * @returns {object} object with stream input and uri
 */
const getZixiStreamActiveInput = (data) => {
    let thisStreamUri = "undefined";

    if (data !== undefined && data[0] !== undefined) {
        if (data[0].input_stream_stats !== undefined) {
            thisStreamUri = data[0].input_stream_stats.connected ? data[0].input_stream_stats.connected : "undefined";
        }
    }
    return {
        thisStreamUri: thisStreamUri,
    };
};

/**
 * Not Applicable for Zixi streams: Gets stream audio level
 * @param {*} data object
 * @returns default value of "N/A"
 */
const getZixiStreamAudioLevel = (data) => {
    let thisStreamAudioLevel = "N/A";
    return {
        thisStreamAudioLevel: thisStreamAudioLevel,
    };
};

/**
 * Not Applicable for Zixi streams: Gets stream active alerts
 * @param {*} data object
 * @returns default value of "N/A"
 */
const getZixiStreamActiveAlerts = (data) => {
    let thisStreamActiveAlerts = "N/A";
    return {
        thisStreamActiveAlerts: thisStreamActiveAlerts,
    };
};

/**
 * Gets stream hardware status
 * @param {*} data object
 * @returns {object} object with encoder cpu, mem, disk
 */
const getZixiStreamHwStatus = (data) => {
    let thisStreamCpu = "N/A";
    let thisStreamMem = "N/A";
    let thisStreamDisk = "N/A";
    let thisStreamGpu1 = "N/A";
    let thisStreamGpu2 = "N/A";
    let thisStreamGpu3 = "N/A";
    let thisStreamGpu4 = "N/A";

    return {
        thisStreamCpu: thisStreamCpu,
        thisStreamMem: thisStreamMem,
        thisStreamDisk: thisStreamDisk,
        thisStreamGpu1: thisStreamGpu1,
        thisStreamGpu2: thisStreamGpu2,
        thisStreamGpu3: thisStreamGpu3,
        thisStreamGpu4: thisStreamGpu4,
    };
};

/**
 * NA for Zixi stream: Gets stream audio normalization
 * @param {*} data object
 * @returns {object} default NA
 */
const getZixiStreamAudioNorm = (data) => {
    let thisStreamAudioNorm = "N/A";

    if (data !== undefined && data[0] !== undefined) {
        if (data[0].config_status !== undefined && data[0].config_status.audio_normalization !== undefined) {
            thisStreamAudioNorm = data[0].config_status.audio_normalization ? "On" : "N/A";
        }
    }
    return {
        thisStreamAudioNorm: thisStreamAudioNorm,
    };
};

/**
 * Gets streams thumbnail url
 * @param {*} data object
 * @returns {object} streams signed thumbnail url
 */
const getZixiStreamThumbnailData = (data) => {
    let thisStreamThumbnailData = "undefined";

    if (data !== undefined && data[0] !== undefined) {
        thisStreamThumbnailData = data[0].binary_image ? data[0].binary_image : "undefined";
    }
    return {
        thisStreamThumbnailData: thisStreamThumbnailData,
    };
};

export {
    getZixiStreamLink,
    getZixiStreamEventNameAndId,
    getZixiStreamUpTime,
    getZixiStreamInputBitrate,
    getZixiStreamActiveInput,
    getZixiStreamAudioLevel,
    getZixiStreamActiveAlerts,
    getZixiStreamHwStatus,
    getZixiStreamAudioNorm,
    getZixiStreamThumbnailData,
};
