import React, { createContext, useReducer } from "react";

const initialState = {
    toggleDark: true,
    showScteEvents: false,
    scteData: {},
    openSideBar: true,
    feedNameList: [],
    avocViewFeedList: [],
    showFeedData: false,
    showAvocStyleFeedData: false,
    checkBoxSectionData: [],
    showCheckboxData: false,
    fromLoginPage: false,
    urlParamsFeedList: [],
    urlParamsAttrList: [],
    dashboardFeedQueryData: {
        dbQueryData: [],
        isDbQueryLoading: true,
        isDbQueryError: false,
        dbQueryErrorMsg: null,
    },
    attributeState: {
        encoder_link: false,
        encoder_event: false,
        encoder_stats: false,
        event_stats: false,
        active_alerts: false,
        image_thumbnail: true,
        download_buttons: false,
        progress_bar: false,
        show_footer: true,
        showCustomLabelField: false,
    },
};

const appReducer = (state, action) => {
    switch (action.type) {
        case "ADD_TO_URL_PARAMS_FEED_QUEUE":
            return {
                ...state,
                urlParamsFeedList: action.urlParamsFeedList,
            };

        case "ADD_TO_URL_PARAMS_ATTRIBUTES_QUEUE":
            return {
                ...state,
                urlParamsAttrList: action.urlParamsAttrList,
            };

        case "GET_DASHBOARD_FEED_QUERY_DATA":
            return {
                ...state,
                dashboardFeedQueryData: action.dashboardFeedQueryData,
            };

        case "SET_FROM_LOGIN_PAGE":
            return {
                ...state,
                fromLoginPage: action.fromLoginPage,
            };

        case "SET_ATTRIBUTE_STATE":
            return {
                ...state,
                attributeState: {
                    ...state.attributeState,
                    ...action.newAttributeState,
                },
            };

        case "ADD_CHECKBOX_SECTION_DATA":
            //Prevent duplicate entries
            if (typeof action.addToCheckBoxSectionData === "object") {
                for (const feed of action.addToCheckBoxSectionData) {
                    if (state.checkBoxSectionData.indexOf(feed) === -1) {
                        state = {
                            ...state,
                            checkBoxSectionData: [...state.checkBoxSectionData, feed],
                        };
                    }
                }
            }

            return state;

        case "REMOVE_CHECKBOX_SECTION_DATA":
            return {
                ...state,
                checkBoxSectionData: [
                    ...state.checkBoxSectionData.filter((o) => o !== action.removeFromCheckBoxSectionData),
                ],
            };

        case "ADD_FEED_DATA":
            //Handle if array of streams
            if (typeof action.showThisFeedName === "object" && action.showThisFeedName.length > 0) {
                for (const feed of action.showThisFeedName) {
                    if (state.feedNameList.indexOf(feed) === -1) {
                        state = {
                            ...state,
                            feedNameList: [...state.feedNameList, feed],
                        };
                    }
                }
            }

            return state;

        case "REMOVE_FEED_DATA":
            return {
                ...state,
                feedNameList: [...state.feedNameList.filter((o) => o !== action.removeThisFeed)],
            };

        case "ADD_TO_AVOC_VIEW_FEED_LIST":
            if (!state.avocViewFeedList.includes(action.newAvocViewFeed)) {
                return {
                    ...state,
                    avocViewFeedList: [...state.avocViewFeedList, action.newAvocViewFeed],
                };
            }
            return state;

        case "REMOVE_FROM_AVOC_VIEW_FEED_LIST":
            return {
                ...state,
                avocViewFeedList: [...state.avocViewFeedList.filter((o) => o !== action.removeThisAvocViewFeed)],
            };

        case "SHOW_FEED_DATA":
            return {
                ...state,
                showFeedData: action.showFeedData,
            };

        case "SHOW_CHECKBOX_DATA":
            return {
                ...state,
                showCheckboxData: action.showCheckboxData,
            };

        case "TOGGLE_DARK_THEME":
            return {
                ...state,
                toggleDark: action.toggleDark,
            };

        case "SHOW_SCTE_EVENTS":
            return {
                ...state,
                showScteEvents: action.showScteEvents,
            };

        case "SET_SCTE_DATA":
            return {
                ...state,
                scteData: action.scteData,
            };

        case "OPEN_SIDEBAR":
            return {
                ...state,
                openSideBar: action.openSideBar,
            };

        default:
            return state;
    }
};

//Create new context for global
const GlobalContext = createContext(undefined);

//Configure Context Provider with reducer function, initial global values and globalContext
const GlobalContextProvider = ({ children }) => {
    const globalContextValue = useReducer(appReducer, initialState);
    return <GlobalContext.Provider value={globalContextValue}>{children}</GlobalContext.Provider>;
};

export { GlobalContext, GlobalContextProvider };
