import React, { useEffect, useState, useContext } from "react";
import { useSearchParams, useNavigate, Navigate } from "react-router-dom";

// import { getStreamsFromAlias, getAliasFromFeedName } from "../js/dashboardUtils";
import { GlobalContext } from "../contexts/GlobalContextStore";
// import { useFeedDataApiQuery } from "../js/useFeedDataQueryUtils";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

// WebURL params component receives feeds & attributes via URL, varifies, parses and loads to respective
// global context states. We cant verify at this stage since we dont have DB data yet.
// Feeds are verified  and acted upon in "FeedSelectionOptions" and attributes are verified and upon in "AttributeSelector"
//This way we prevent multiple DB fetch and maintain a seperation between WebParams and userDirected workflows.

export const WebUrlParamsHandler = () => {
    // const useFeedDataApiQueryResultObj = useFeedDataApiQuery();
    const [searchParams] = useSearchParams();
    const [storeStates, storeDispatch] = useContext(GlobalContext);
    const [isParamsLoaded, setIsParamsLoaded] = useState(false);
    const currentParams = Object.fromEntries([...searchParams]);

    useEffect(() => {
        // const currentParams = Object.fromEntries([...searchParams]);
        // console.log("loading tenant data in WebUrlParams"); //debug aam
        // console.log("URL params=>"); //debug aam
        // console.log(currentParams); //debug aam

        const urlParamsFeedList = [];
        const urlParamsAttrList = [];

        if (Object.keys(currentParams).length > 0) {
            //Check if all keys are valid before proceeding
            //Only "feeds" && "attributes" or just "feeds" allowed at this time

            if (Object.keys(currentParams).includes("feeds")) {
                for (const feed of currentParams["feeds"].split(",")) {
                    urlParamsFeedList.push(feed);
                }
                storeDispatch({
                    type: "ADD_TO_URL_PARAMS_FEED_QUEUE",
                    urlParamsFeedList: urlParamsFeedList,
                });
            }
            //Process attributes
            if (Object.keys(currentParams).includes("attributes")) {
                for (const attr of currentParams["attributes"].split(",")) {
                    urlParamsAttrList.push(attr);
                }
                storeDispatch({
                    type: "ADD_TO_URL_PARAMS_ATTRIBUTES_QUEUE",
                    urlParamsAttrList: urlParamsAttrList,
                });
            }
        }
        setIsParamsLoaded(true);
    }, []);

    return (
        <>
            {isParamsLoaded ? (
                <Navigate to="/" replace={true} />
            ) : (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "bottom", height: "inherit" }}>
                    <CircularProgress />
                </Box>
            )}
        </>
    );
};
