import { styled } from "@mui/material/styles";

/**
 * Styled div component with theme for drawer header
 * @param {theme} theme
 * @returns {obj} styled header obj
 */
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default DrawerHeader;
