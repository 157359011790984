import React, { useContext } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Box, Checkbox, Chip, FormControlLabel, FormGroup } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/system";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";


import { regionList } from "../js/appConstants";
import { GlobalContext } from "../contexts/GlobalContextStore";
import { getStreamsFromAlias, getAliasFromFeedName, isFeed } from "../js/dashboardUtils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

/**
 * Styled cancel icon component
 */
const StyledCancelIcon = styled(HighlightOffIcon, {
    name: "StyledCancelIcon",
    slot: "Wrapper",
})(({ theme }) => ({
    "&:hover": { color: theme.palette.error.dark },
    fontSize: "0.8rem",
    // "marginLeft": "-10px"
}));

/**
 * Helper component to process URL params feeds
 * @returns {JSX}  empty
 */
const ProcessWebUrlFeeds = () => {
    const [storeStates, storeDispatch] = useContext(GlobalContext);
    React.useEffect(() => {
        if (storeStates.urlParamsFeedList.length > 0) {
            for (const feed of storeStates.urlParamsFeedList) {
                let feedAlias = getAliasFromFeedName(feed, storeStates.dashboardFeedQueryData.dbQueryData[0]);
                // console.log("Got feed alias: ", feedAlias); //DEBUG AAM
                if (feedAlias !== undefined) {
                    storeDispatch({
                        type: "ADD_CHECKBOX_SECTION_DATA",
                        addToCheckBoxSectionData: [feedAlias],
                    });
                    storeDispatch({ type: "SHOW_CHECKBOX_DATA", showCheckboxData: true });
                    //Add feeds to display list directly
                    const showFeedData = getStreamsFromAlias(
                        feedAlias,
                        storeStates.dashboardFeedQueryData.dbQueryData[0]
                    );
                   
                    if (showFeedData && showFeedData.length > 0) {
                        //Hash here for local test
                        storeDispatch({
                            type: "ADD_FEED_DATA",
                            showThisFeedName: showFeedData,
                        });
                        storeDispatch({ type: "SHOW_FEED_DATA", showFeedData: true });
                    }
                    storeDispatch({
                        type: "ADD_TO_AVOC_VIEW_FEED_LIST",
                        newAvocViewFeed: feedAlias,
                    });
                }
            }
        }
    }, []);

    return <> </>;
};

/**
 * Feed / Stream selection component
 * @returns {JSX}  Feed / Stream selection component
 */
export function FeedSelectionOptions() {
    const [tenant, setTenant] = React.useState("");
    const [feeds, setFeeds] = React.useState([]);
    const [showFeedOptions, setShowFeedOptions] = React.useState(false);
    const [checkBoxFeed, setCheckBoxFeed] = React.useState([]);
    const [storeStates, storeDispatch] = useContext(GlobalContext);

    
    /**
     * handle tenant selection
     * @param {*} event
     */
    const handleTenantSelect = (event) => {
        setTenant(event.target.value);
        setShowFeedOptions(true);
        //Remove feed from dropdown
        setCheckBoxFeed([]);
    };

    /**
     * Handles feed selection. sets checkboxes
     * @param {*} event
     */
    const handleFeedSelect = (event) => {
        const {
            target: { value },
        } = event;
        setCheckBoxFeed(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value
        );
        //Avoid duplicate addtions to checkbox list
        value.map((s) => {
            if (!feeds.includes(s)) {
                setFeeds([...feeds, s]);
                storeDispatch({
                    type: "ADD_CHECKBOX_SECTION_DATA",
                    addToCheckBoxSectionData: [s],
                });
                storeDispatch({ type: "SHOW_CHECKBOX_DATA", showCheckboxData: true });

                //Add feeds to display list directly
                const showFeedData = getStreamsFromAlias(s, storeStates.dashboardFeedQueryData.dbQueryData[0]);
                if (showFeedData && showFeedData.length > 0) {
                    //Hash here for local test
                    storeDispatch({
                        type: "ADD_FEED_DATA",
                        showThisFeedName: showFeedData,
                    });
                    storeDispatch({ type: "SHOW_FEED_DATA", showFeedData: true });
                }

                //Add to AvocView Feeds list
                storeDispatch({
                    type: "ADD_TO_AVOC_VIEW_FEED_LIST",
                    newAvocViewFeed: s,
                });
            }
            return "done";
        });
    };

    /**
     * handles checkboxes select/de-select
     * @param {*} e checkbox event
     */
    const handleCheckBoxSelect = (e) => {
        //Add remove from display list based on select/de-selcet
        if (e.target.checked === true) {
            const showFeedData = getStreamsFromAlias(e.target.name, storeStates.dashboardFeedQueryData.dbQueryData[0]);
            if (showFeedData && showFeedData.length > 0) {
                //Hashed for local test
                storeDispatch({
                    type: "ADD_FEED_DATA",
                    showThisFeedName: showFeedData,
                });
                storeDispatch({ type: "SHOW_FEED_DATA", showFeedData: true });
            }
            //Add to AvocView Feeds list
         
            if (isFeed) {
                storeDispatch({
                    type: "ADD_TO_AVOC_VIEW_FEED_LIST",
                    newAvocViewFeed: e.target.name,
                });
            }
            //If target is unchecked
        } else if (e.target.checked === false) {
            //Check if feed is a feed or stream
            const thisfeed = e.target.name;
            const isStream = regionList.includes(thisfeed.split("-").slice(-2).join("-"));
            //Feed is stream remove from display pane
            if (isStream) {
                storeDispatch({ type: "REMOVE_FEED_DATA", removeThisFeed: thisfeed });
            } else {
                // Input is Feed, Get its streams and remove from display pane.
                const removeFeedData = getStreamsFromAlias(thisfeed, storeStates.dashboardFeedQueryData.dbQueryData[0]);
                if (removeFeedData && removeFeedData.length > 0) {
                    removeFeedData.map((stream) => storeDispatch({ type: "REMOVE_FEED_DATA", removeThisFeed: stream }));
                }
                //Remove from Avoc Display (feeds only)
                storeDispatch({
                    type: "REMOVE_FROM_AVOC_VIEW_FEED_LIST",
                    removeThisAvocViewFeed: thisfeed,
                });
            }
        }
    };

    /**
     * clears feeds from options upon clicking anywhere on canvas.
     * beeded due to bug in MUI options.
     */
    const clearFeedOptions = () => {
        // Remove feed from dropdown
        setCheckBoxFeed([]); // needed to label feed options
    };

    /**
     * handle cancel button click infront of checkbox.
     * cancels feeds from display and cleans up feeds / checkboxes.
     * @param {*} thisfeed object
     */
    const handleCancelButtonClick = (thisfeed) => {
        //Check if feed is a feed or stream
        const isStream = regionList.includes(thisfeed.split("-").slice(-2).join("-"));
        //Feed is stream remove from checkbox as well as display pane
        if (isStream) {
            storeDispatch({ type: "REMOVE_FEED_DATA", removeThisFeed: thisfeed });
            storeDispatch({
                type: "REMOVE_CHECKBOX_SECTION_DATA",
                removeFromCheckBoxSectionData: thisfeed,
            });
        } else {
            // Feed is Feed, remove from checkbox section. Get its streams and remove from display pane.
            storeDispatch({
                type: "REMOVE_CHECKBOX_SECTION_DATA",
                removeFromCheckBoxSectionData: thisfeed,
            });
            const removeFeedData = getStreamsFromAlias(thisfeed, storeStates.dashboardFeedQueryData.dbQueryData[0]);
            if (removeFeedData && removeFeedData.length > 0) {
                removeFeedData.map((stream) => storeDispatch({ type: "REMOVE_FEED_DATA", removeThisFeed: stream }));
            }
            //Remove from Avoc Display (feeds only)
            storeDispatch({
                type: "REMOVE_FROM_AVOC_VIEW_FEED_LIST",
                removeThisAvocViewFeed: thisfeed,
            });
        }

        //Remove from checkbox feed section
        if (feeds.includes(thisfeed)) {
            setFeeds([...feeds.filter((o) => o !== thisfeed)]);
            storeDispatch({
                type: "REMOVE_CHECKBOX_SECTION_DATA",
                removeFromCheckBoxSectionData: thisfeed,
            });
        }

        // Remove feed from dropdown
        setCheckBoxFeed([]); // needed to label feed options
    };

    return (
        <>
            {storeStates.dashboardFeedQueryData.isDbQueryLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "inherit" }}>
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <ProcessWebUrlFeeds />
                    <FormControl sx={{ m: 1, minWidth: 120 }} size="medium" variant="standard">
                        <InputLabel sx={{ fontSize: "14px" }} id="tenant-selection-menu">
                            Select Tenants
                        </InputLabel>
                        <Select
                            labelId="tenant-selection-menu"
                            id="tenant-selection-menu"
                            name={"tenant"}
                            value={tenant ? tenant : ""}
                            label={"tenant"}
                            onChange={handleTenantSelect}
                            sx={{ fontSize: "12px" }}
                        >
                            <MenuItem disabled sx={{ fontSize: "12px" }} value={""}>
                                Select Tenants
                            </MenuItem>
                            {Object.keys(storeStates.dashboardFeedQueryData.dbQueryData[0]).map((tenant, idx) => (
                                <MenuItem key={idx} sx={{ fontSize: "12px" }} value={tenant}>
                                    {" "}
                                    {tenant}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {showFeedOptions && (
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small" variant="standard">
                            <InputLabel sx={{ fontSize: "12px" }} id="feed-options-menu">
                                Select Feeds
                            </InputLabel>

                            <Select
                                labelId="feed-options-menu"
                                id="feed-options-menu"
                                name={""}
                                multiple
                                value={checkBoxFeed}
                                label={"feeds"}
                                onChange={handleFeedSelect}
                                sx={{ fontSize: "8px" }}
                                renderValue={(selected) => (
                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                        {selected.map((value) => (
                                            <Chip key={value} label={value} sx={{ fontSize: "0.5rem" }} />
                                        ))}
                                    </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                <MenuItem disabled sx={{ fontSize: "12px" }} value={""}>
                                    Select Feeds
                                </MenuItem>
                                {storeStates.dashboardFeedQueryData.dbQueryData[0][tenant]
                                    .map((o) => o.alias)
                                    .map((feed, idx) => (
                                        <MenuItem key={idx} sx={{ fontSize: "12px" }} value={feed}>
                                            {" "}
                                            {feed}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                    <Divider sx={{ m: 1 }} />

                    {storeStates.showCheckboxData && (
                        <FormControl component="fieldset">
                            <ClickAwayListener onClickAway={clearFeedOptions}>
                                <FormGroup aria-label="position" sx={{ fontSize: "12px" }}>
                                    {storeStates.checkBoxSectionData.map((feed) => (
                                        <div key={feed}>
                                            <FormControlLabel
                                                componentsProps={{ typography: { variant: "caption" } }}
                                                control={
                                                    <Checkbox
                                                        defaultChecked
                                                        // checked= {checked}
                                                        size="small"
                                                        name={feed}
                                                        onChange={handleCheckBoxSelect}
                                                    />
                                                }
                                                label={feed}
                                                labelPlacement="end"
                                            />
                                            <IconButton onClick={() => handleCancelButtonClick(feed)}>
                                                <StyledCancelIcon />
                                            </IconButton>
                                        </div>
                                    ))}
                                </FormGroup>
                            </ClickAwayListener>
                        </FormControl>
                    )}
                </React.Fragment>
            )}
        </>
    );
}
