const prodConfig = {
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_cqdyA3nmn",
        userPoolWebClientId: "5td1k0ppnseggcdfi9l8sppm9t",
        identityPoolId: "us-west-2:6bd2afef-b693-4354-9dfe-6841114431e4",
        mandatorySignIn: false,
        oauth: {
            domain: "dvlive-encoder-dashboard.auth.us-west-2.amazoncognito.com",
            scope: ["openid"],
            redirectSignIn: "https://prod.streampreview.amazonavoc.com/",
            redirectSignOut: "https://prod.streampreview.amazonavoc.com/signout/",
            responseType: "code",
        },
    },
    API: {
        endpoints: [
            {
                name: "Gamma",
                endpoint: "https://gamma.api.amazonavoc.com/",
                region: "us-west-2",
            },
            {
                name: "Prod",
                endpoint: "https://prod.api.amazonavoc.com/",
                region: "us-west-2",
            },
        ],
    },
};

const gammaConfig = {
    Auth: {
        region: "us-west-2",
        userPoolId: "us-west-2_cqdyA3nmn",
        userPoolWebClientId: "7icfq7k2ncsr62n3chr9vckntt",
        identityPoolId: "us-west-2:6bd2afef-b693-4354-9dfe-6841114431e4",
        mandatorySignIn: false,
        oauth: {
            domain: "dvlive-encoder-dashboard.auth.us-west-2.amazoncognito.com",
            scope: ["openid"],
            redirectSignIn: "https://gamma.streampreview.amazonavoc.com/",
            redirectSignOut: "https://gamma.streampreview.amazonavoc.com/signout/",
            responseType: "code",
        },
    },
    API: {
        endpoints: [
            {
                name: "Gamma",
                endpoint: "https://gamma.api.amazonavoc.com/",
                region: "us-west-2",
            },
            {
                name: "Prod",
                endpoint: "https://prod.api.amazonavoc.com/",
                region: "us-west-2",
            },
        ],
    },
};

//Initialize local variables
let envConfig;
console.log("Node ENV", process.env.NODE_ENV);
console.log("React App ENV", process.env.REACT_APP_API_ENVIRONMENT);

//Auto Switch between different environments based on code branch and URL's
if (process.env.NODE_ENV === "production" && process.env.REACT_APP_API_ENVIRONMENT === "Prod") {
    console.log("Using Prod OAuth config and Prod API");
    envConfig = { ...prodConfig };
} else if (process.env.NODE_ENV === "production" && process.env.REACT_APP_API_ENVIRONMENT === "Gamma") {
    console.log("Using Gamma OAuth config and Gamma API");
    envConfig = { ...gammaConfig };
} else {
    console.log("Using Gamma config until Local Oauth config is setup");
    envConfig = { ...gammaConfig };
}

export default envConfig;
