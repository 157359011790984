import { useQuery } from "@tanstack/react-query";
import { getFeedData } from "./feedDataApiUtils";

/**
 * Cyclic query hook that makes API query to lambda
 * @param {*} thisStreamName
 * @returns {obj} Object containing data /error
 */
function useFeedDataApiQuery() {
    // console.log("Start going through useFeedDataApiQuery Function"); //debug aam
    return useQuery(["feeds"], async () => {
        let feedData = {};

        //Obtaining feedData
        // console.log("Obtaining FeedData Function"); //debug aam
        const getFeedDataObj = await getFeedData();
        feedData = { ...feedData, ...getFeedDataObj[0] };

        //check NBA tenant since it is set to static
        if (!feedData.NBA) {
            console.warn("Cant rennder feedData, NBA tenant not found in response ");
        }
        return [feedData];
    });
}

export { useFeedDataApiQuery };
