import React, { useContext } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { Container } from "@mui/system";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { styled } from "@mui/material/styles";

import { GlobalContext } from "../contexts/GlobalContextStore";

/**
 * Styled text-field component
 * @param {*} theme
 * @returns {obj} styled text field
 */
const ValidationTextField = styled(TextField)(({ theme }) => ({
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    fontSize: 8,
    width: "110%",
    marginLeft: "-20px",
    "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "& input:valid + fieldset": {
        borderColor: "green",
        borderWidth: 2,
    },
    "& input:invalid + fieldset": {
        borderColor: "red",
        borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
        borderLeftWidth: 6,
        padding: "2px !important",
    },
}));

let initData = {
    stream_name: undefined,
    event_name: undefined,
    event_id: undefined,
    datetime: undefined,
    live_service_endpoint: undefined,
    active_alerts: undefined,
    thumbnail_url: undefined,
    event_status: undefined,
    event_run_status: {
        uptime: undefined,
        input: undefined,
        input_uri: undefined,
        video_framerate: undefined,
        audio_level: undefined,
    },
    hardware_status: {
        cpu: undefined,
        mem: undefined,
        disk_available: undefined,
        gpu1: undefined,
        gpu2: undefined,
        gpu3: undefined,
        gpu4: undefined,
    },
    config_status: {
        audio_normalization: undefined,
    },
    scte_events: [],
};

function FeedInputForm() {
    const [feedName, setFeedName] = React.useState(null);
    const [feedNameError, setfeedNameError] = React.useState(false);
    const textInput = React.useRef(null);
    const [storeStates, storeDispatch] = useContext(GlobalContext);

    /**
     * Handles feed name selection.
     * @param {*} event
     */
    const handleChange = (event) => {
        setFeedName(event.target.value);
        setfeedNameError(false);
    };

    /**
     * Handles feed name click. Kicks off feed and sets checkboxes
     * @param {*} event
     */
    const handleOnClick = async (event) => {
        event.preventDefault();
        setFeedName(event.target.value);
        setfeedNameError(false);

        if (feedName === "" || feedName === undefined) {
            setfeedNameError(true);
        }

        if (feedName) {
            //Get input feed data in proper data structure
            let feedInoutData = feedName.replace(/\s/g, "").split(",");

            //Add streams to display plane queue
            //Hashed for local test
            storeDispatch({ type: "ADD_FEED_DATA", showThisFeedName: feedInoutData });
            storeDispatch({
                type: "ADD_CHECKBOX_SECTION_DATA",
                addToCheckBoxSectionData: feedInoutData,
            });
            storeDispatch({ type: "SHOW_CHECKBOX_DATA", showCheckboxData: true });

            // iterate through data and update global initial states

            feedInoutData.map((s) => {
                let feedData = {};
                feedData[s.showStreamName] = initData;
                feedData[s.showStreamName].stream_name = s.showStreamName;
            });

            //Now show data on display pane
            storeDispatch({ type: "SHOW_FEED_DATA", showFeedData: true });
            setFeedName(null);
            textInput.current.value = "";
        }
    };

    return (
        <Container>
            <Box
                sx={{
                    width: 800,
                    maxWidth: "100%",
                }}
            >
                <form onSubmit={handleOnClick} autoComplete="off">
                    <ValidationTextField
                        label="Enter Stream Name"
                        required
                        variant="outlined"
                        id="validation-outlined-input"
                        error={feedNameError}
                        inputRef={textInput}
                        helperText={feedNameError ? "FeedName required" : ""}
                        onChange={(event) => handleChange(event)}
                        FormHelperTextProps={{ style: { fontSize: 12 } }}
                        InputLabelProps={{ style: { fontSize: 12 } }}
                        InputProps={{
                            style: { fontSize: 12 },
                            endAdornment: (
                                <IconButton onClick={handleOnClick} type="submit">
                                    <KeyboardArrowRightIcon />
                                </IconButton>
                            ),
                        }}
                    />
                </form>
            </Box>
        </Container>
    );
}
export default FeedInputForm;
